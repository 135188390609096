<template>
  <section class="tender-details main-wrapper">
    <div class="main-content">
      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />

      <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
        <div
          class="
            is-flex is-align-items-center is-justify-content-space-between
            mb-4
          ">
          <h2
            class="
              main-title
              is-size-3-5-mobile
              has-text-left has-text-centered-mobile
              mr-4
            "
          >
            Помощь
          </h2>
        </div>
        <div class="container help-container">
          <div class="help-container__item">
            <h4>Как зарегистрировать и подать заявку на участие в тендерах?</h4>
            <ul>
              <li>Заполнить информацию об организации во вкладке организация</li>
              <li>Отметить категории и виды работ по которым планируется принимать участие</li>
              <li>Прикрепить необходимые документы</li>
              <li>Отправить информацию об организации на проверку</li>            
            </ul>
            <p>После выполнения указанных шагов необходимо дождаться результата проверки. В случае положительного решения по результатам проверки открывается возможность просматривать список тендеров по подтвержденным категориям и видам работ.</p>
            <p>На вкладке «Настройка уведомлений» можно отметить интересующий тип уведомлений, которые вам необходимо получать.</p>
            <p>Для того чтобы изменить информацию об организации либо категории работ после того как статус Ваше организации стал «Проверен», необходимо принудительно перейти в «Режим редактирования» изменить информацию и снова отправить запрос на проверку.</p>
            <p>Если в системе Ваша организация уже зарегистрирована, Вы можете отправить запрос на прикрепление Вашего аккаунта к организации. Для этого на вкладке «Запрос/приглашение» необходимо создать новый запрос, указать ИНН организации. После подтверждения запроса Ответственным сотрудником Вашей организации Вы сможете работать в системе.</p>
            <p>Если вы зарегистрировали организацию и хотите добавить нового участника либо передать права на управление, то Вам необходимо создать приглашение, указать email зарегистрированного пользователя системы, указать тип приглашения и нажать «отправить»</p>
          </div>
          <div class="help-container__item">
            <h4>Как подать заявку?</h4>
            <p>После аккредитации организации становится доступен пункт меню «Тендеры». На этой странице отображается список доступных тендеров (в зависимости от аккредитованных категорий / видов работ).</p>
            <p>При клике по наименованию тендера открывается детальная страница тендера где размещена информация о тендере, файлы технического задания, контактная информация контактного лица и форма подачи заявки на тендер.</p>
            <p>Все созданные заявки доступны Вам как на странице тендера, так и на странице «Заявки».</p>
          </div>
          <div class="help-container__item">
            <h4>Как выбирается победитель?</h4>
            <p>После окончания периода приема заявок, тендер переходит в режим подведения итогов. В этот момент тендерная комиссия анализирует поданные заявки и выбирает победителя. Каждой заявке назначается один из статусов «Принята к рассмотрению», «Отклонена», «Победитель». После того как выбран победитель, тендер принимает статус «Окончен».</p>
          </div>
          <div class="help-container__item">
            <h4>Есть вопрос по определенному тендеру?</h4>
            <p>1. Перейдите в раздел подачи заявки на интересующий тендер</p>   
            <p>2. Откройте вкладку «Сообщения»</p>
            <p>3. Задайте необходимые вопросы</p>
          </div>
          <div class="help-container__item">
            <h4>Остались вопросы?</h4>
            <p>
              Ознакомьтесь c <a href="/instruction.pdf" target="_blank">инструкцией</a>, 
              воспользуйтесь <a href="#" v-scroll-to="'#feedback-form'">формой обратной связи</a>, обратитесь в call-центр или напишите нам. <br>Время работы call-центра: (пн-пт) 10:00 - 18:00</p>
            <div class="help-container__item-contacts">
              <div class="help-container__item-contact help-container__item-phone">
                <a href="tel:84996474777">+7 499 647 47 77</a>
                <span class="muted-text">По вопросам сотрудничества</span>
                </div>
              <div class="help-container__item-contact help-container__item-email">
                <a href="mailto:e-tender@evraz.com">e-tender@evraz.com</a>
                <span class="muted-text">Электронная почта для поддержки</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container feedback-form is-flex" id="feedback-form">
          <div class="feedback-form__left">
            <h4>Обратная связь</h4>
            <p>Здесь вы можете задать любые вопросы, касающиеся работы в открытой части сайта:  входа в личный кабинет, регистрации и пр. <br>А также сообщить об ошибке</p>
          </div>
          <div class="feedback-form__right">
            <div>
              <div class="feedback-form__item">
                <label for="">Имя</label>
                <input type="text" placeholder="" v-model="authorizedName">
              </div>
              <div class="feedback-form__item">
                <label for="">E-mail</label>
                <input type="text" placeholder="" v-model="email">
              </div>
              <div class="feedback-form__item">
                <label for="">Форма для связи</label>
                <textarea v-model="message"></textarea>
              </div>
              <div class="feedback-form__item is-flex policy-checkbox" @click="policyChecked = !policyChecked">
                <box-checked v-if="policyChecked"/>
                <box-unchecked v-else/>
                <p>Отправляя сообщение вы даете согласие на <a href="#">обработку персональных данных</a></p>
              </div>
              <div class="feedback-form__item">
                <button class="button is-normal main-button is-fullwidth-mobile-only" @click="helpFormSubmit">
                  <span>Отправить сообщение</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-vue />
  </section>
</template>

<script>

import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import BoxUnchecked from "@/components/icons/BoxUnchecked.vue";
import BoxChecked from '@/components/icons/BoxChecked.vue';
import authService from "@/services/auth.service";
import httpClient from "@/services/http.service";

export default {
  name: 'Help',
  data() {
    return {
      policyChecked: true,
      message: '',
      authorizedName: authService.getAuthorizedName(),
      email: authService.getEmail()
    }
  },
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
    BoxChecked,
    BoxUnchecked,
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          title: "Помощь",
          link: null,
        },
      ];
    },
    getAuthorizedName(){
      return authService.getAuthorizedName()
    },
    getEmail(){
      return authService.getEmail()
    }
  },
  methods: {
    async helpFormSubmit() {
      const formData = {
        username: this.authorizedName,
        email: this.email,
        message: this.message
      }
      const { data } = await httpClient.post('/restapi/send_feedback_form', formData);

      if (data && data.status === 'success') {
        this.$buefy.toast.open({
          message: 'Сообщение успешно отправлено',
          type: "is-success",
          duration: 4000
        });
        this.authorizedName = ''
        this.email = ''
        this.message = ''
      }
      else
        this.$buefy.toast.open({
          message: 'Произошла ошибка при отправке формы',
          type: "is-danger",
          duration: 4000
        });
    }
  }
};
</script>

<style scoped lang="scss">

.help-container {
  width: 100%;
  box-shadow: 5px 10px 50px 0px rgba(175, 175, 175, 0.25);
  padding: 60px;
  border-radius: 10px;
  .help-container__item {
    ul {
      list-style-type: none;
      li {
        color: #323232;
        position: relative;
        padding-left: 20px;
        margin: 10px 0;
        font-weight: 300;
        &:before {
          content: '';
          position: absolute;
          left: 0px;
          top: 6px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: linear-gradient(94.43deg, #A2165B 0%, #5A004B 100%);
        }
      }
    }
    h4 {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-weight: 500;
    }
    > a {
      text-decoration: underline;
    }
    > p {
      color: #323232;
      font-weight: 300;
      margin: 10px 0;
    }
    + .help-container__item {
      border-top: 2px solid #E4E4E4;
      margin-top: 30px;
      padding-top: 30px;
    }
  }
  .help-container__item-contacts {
    margin-top: 20px;
    display: flex;
    .help-container__item-contact {
      padding-left: 30px;
      display: flex;
      flex-wrap: wrap;
      a {
        color: #323232 !important;;
      }
      .muted-text {
        width: 100%;
        display: block;
        color: #959595;
        font-size: 13px;
      }
      + .help-container__item-contact {
        margin-left: 20px;
      }
      &.help-container__item-phone {
        background: url(../assets/img/black-call-icon.svg) no-repeat left center;
      }
      &.help-container__item-email {
        background: url(../assets/img/black-email-icon.svg) no-repeat left center;
      }
    }
  }
}
.feedback-form {
  margin-top: 60px;
  box-shadow: 5px 10px 50px 0px rgba(175, 175, 175, 0.25);
  border-radius: 10px;
  overflow: hidden;
  .feedback-form__left {
    width: 45%;
    background: url(../assets/img/help-form-bg.svg) no-repeat center / cover;
    color: white;
    padding: 60px;
    h4 {
      font-size: 32px;
      margin-bottom: 10px;
    }
    p {
      line-height: 1.5;
      padding-right: 40px;
    }
  }
  .feedback-form__right {
    width: 55%;
    padding: 60px;
    .feedback-form__item {
      label {
        color: #6F6F6F;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 5px;
        display: block;
      }
      input, 
      textarea {
        background: #FBFBFB;
        border: 1px solid #EDEDED;
        border-radius: 3px;
        width: 100%;
        padding: 10px;
      }
      input {
        height: 44px;
      }
      textarea {
        height: 200px;
        resize: none;
      }
      + .feedback-form__item {
        margin-top: 20px;
      }
    }
  }
}
.policy-checkbox {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .help-container {
    padding: 30px;
    text-align: left;
  }
  .help-container__item-contacts {
    flex-wrap: wrap;
    .help-container__item-contact {
      width: 100%;
      + .help-container__item-contact {
        margin-left: 0 !important;
        margin-top: 20px;
      }
    }
  }
  .feedback-form {
    flex-wrap: wrap;
    .feedback-form__left {
      width: 100%;
      padding: 30px;
      text-align: left;
    }
    .feedback-form__right {
      text-align: left;
      width: 100%;
      padding: 30px;
      textarea {
        height: 120px !important
      }
    }

  }
}

</style>